import React from "react";
import { css } from "@emotion/react";
import { Div } from "../components/Markup";
import { Small} from "../components/Typography";
import COLORS from "../styles/colors"

const FootnotesBlock = ({ footnotes }) => {
    if (typeof footnotes === "undefined" || footnotes === null || !footnotes) {
      return null;
    } else {
      return (
        <Div>
          <Small
            css={css`
              letter-spacing: normal;
              border-top: 1px solid ${COLORS.black};
              border-image: linear-gradient(to right, ${COLORS.black}, ${COLORS.white} 66%);
              border-image-slice: 1;
            `}
            pt="xs"
            mb="md"
            dangerouslySetInnerHTML={{ __html: footnotes }}
          />
        </Div>
      );
    }
  };

  export default FootnotesBlock;