import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import Image from "gatsby-image";
import { Div, Container, ContainerSmall, Section } from "../components/Markup";
import TexturaNavigator from "../components/TexturaNavigator"
import FootnotesBlock from "../components/Footnotes"
import { Title, Highlight, Paragraph, Label, Small} from "../components/Typography";
import COLORS from "../styles/colors"

const TextBlock = ({ text }) => {
  return (
    <Div>
      <Paragraph mb="md" dangerouslySetInnerHTML={{ __html: text }}/>
    </Div>
  );
};

const ImageBlock = ({ image, caption }) => {
  let fotographer
  if (caption) {
    fotographer = caption
  } else {
    fotographer = "Ахинора А."
  }

  return (
    <Div>
      <Image fluid={image && image.fluid} />
      <Small mt="xs" mb="md">
        фотосинтеза: {fotographer}
      </Small>
    </Div>
  );
};

const HeaderImage = ({ featuredImage }) => {
  let fotographer
  if (featuredImage.title) {
    fotographer = featuredImage.title  // TODO: change to author
  } else {
    fotographer = "Ахинора А."
  }

  return (
    <Div 
    mb="sm"
    css={css`
      @media screen and (orientation:landscape) {
        position: relative;
        overflow: hidden;
        height: 60vh;
      }
    `}
    >
      <Image
        css={css`
          @media screen and (orientation:landscape) {
            position: absolute;
            top: 50%;
            left: 50%;
            min-height: 100%;
            min-width: 100%;
            transform: translate3d(-50%, -50%, 0);
          }
        `}
        fluid={featuredImage && featuredImage.fluid}>
      </Image>
      <Small mt="xs" mb="md">
        фотосинтеза: {fotographer}
      </Small>
    </Div>
  );
};

const ProjectPage = (props) => {

  const { title, contentHighlight, featuredImage, content, footnotes } = props.data.queried_data;
  const {nextitem, previousitem} = props.pageContext   // "slug" can be extracted also from here
 
  return (
    <div>
      <Section pt="lg" pb="md">
        <Container>
          <HeaderImage featuredImage={ featuredImage }/>
          <Div pt="md" pb="md">
            <Label
              css={css`
                text-align: center;
                letter-spacing: 0.1em;
              `}
            >
              текстура
            </Label>
            <Title
              mb="sm"
              css={css`
                text-align: center;
                letter-spacing: 0.1em;
              `}
            >
              {title}
            </Title>
            <span
              css={css`
                width: 1px;
                height: 90px;
                background: ${COLORS.black};
                display: block;
                margin: 0 auto;

                @media (min-width: 768px) {
                  width: 1.5px;
                  height: 150px;
                }
              `}
            ></span>
          </Div>
        </Container>
        {
          // TODO: set logic to not plot Highlight if none is present! 
        }
        <ContainerSmall>
          <Highlight>{contentHighlight}</Highlight>
        </ContainerSmall>
      </Section>
      <Section pb="lg">
        <ContainerSmall>
          {content &&
            content.map(item => {
              if (item.__typename === "DatoCmsTextBlock") {
                return <TextBlock key={item.id} {...item} />;
              } else {
                return <ImageBlock key={item.id} {...item} />;
              }
            })}
            {
              // TODO: set logic to not plot FootnotesBlock if none is present! 
            }
            <FootnotesBlock footnotes={footnotes}/>
            <TexturaNavigator nextitem={nextitem} previousitem={previousitem}/>
        </ContainerSmall>
      </Section>
      
    </div>
  );
};

export default ProjectPage;

export const query = graphql`
  query TexturePageQuery($slug: String!) {
    queried_data: datoCmsTextura(slug: { eq: $slug }) {
      id
      title
      featuredImage {
        title
        fluid {
          aspectRatio
          src
          srcSet
        }
      }
      footnotes
      highlight
      contentHighlight
      content {
        ... on DatoCmsTextBlock {
          id
          text
        }
        ... on DatoCmsImageBlock {
          id
          image {
            fluid {
              aspectRatio
              src
              srcSet
            }
          }
          caption
        }
      }
    }
  }
`;
