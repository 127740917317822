import React from "react";
import { css } from "@emotion/react";
import { Div, Container} from "../components/Markup";
import Button from "./Button";
  


const Navigator = ({item, label}) => {   
    
    let slug
    if (item) {
        slug = item.slug
    } else {
        slug = ""
    }

    return(
      <Div
        css={css`
          display: flex;
          justify-content: center;
        `}
      >
        <Button url={"/" + slug} text={label}/>
      </Div>
    );
}

const TexturaNavigator = ({previousitem, nextitem}) => {
    return (
      <Container>
        <Div flex>
          <Div mb="sm" css={css`width: 50%;`}>
            <Navigator item={ previousitem } label="предишна"/>
          </Div>
          <Div mb="sm" css={css`width: 50%;`}>
            <Navigator item={ nextitem } label="следваща"/>
          </Div>
        </Div>
      </Container>
    );
  };

export default TexturaNavigator;;